<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item 
            prop="financial_account_id" label="财务账户">
          <a-select style="width:220px;" placeholder="请选择账户"
               v-model="financial_account_id"
               @change="changeAccount">
            <a-select-option v-for="(item, index) in financeAccountList"
                 :value="item.financial_account_id"
                 :key="index">{{item.financial_account_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="activeTab != 4" prop="search_date" label="选择时间">
          <a-range-picker
            style="width:300px"
            v-model="search_date"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item v-if="activeTab == 4" prop="end_date" label="截止时间">
          <a-date-picker
            style="width:200px" placeholder="截止日期"
            v-model="end_date"
            value-format="YYYY-MM-DD"
            @change="onChangeEndDate"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button :loading="isLoading" type="primary" @click="handlerSearch">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="mt-2">
			<a-tabs type="card" class="w-full" @change="changeTabs" :activeKey="activeTab">
				<a-tab-pane v-for="tab in tabList" :key="tab.id" :tab="tab.name"></a-tab-pane>
			</a-tabs>
    </div>
    
    <template v-if="activeTab==0">
			<div class="text-sm mt-4 mb-2">订单总览（根据下单时间筛选）</div>
			<base-table style="max-width:800px;"
				:columnsData="columns_order"
				:tableData="tableData_order"
			></base-table>
			<div class="text-sm mt-4 mb-2">退费数据（根据退费时间筛选）</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_refund"
				:tableData="tableData_refund"
			></base-table>
			<div class="text-sm mt-4 mb-2">订单来源分布（单位:笔）</div>
			<base-table style="max-width:900px;"
				:columnsData="columns_source_simple"
				:tableData="tableData_source"
			></base-table>
			<div class="text-sm mt-4 mb-2">支付方式分布（单位:元）</div>
			<base-table  style="max-width:900px;"
				:columnsData="columns_pay_simple"
				:tableData="tableData_pay"
			></base-table>
    </template>

    <template v-if="activeTab==1">
			<div class="text-sm mt-4 mb-2">充值</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_vipcard_recharge"
				:tableData="tableData_vipcard"
			></base-table>
			<div class="text-sm mt-4 mb-2">在线消费</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_vipcard_online"
				:tableData="tableData_vipcard"
			></base-table>
			<div class="text-sm mt-4 mb-2">线下消费</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_vipcard_offline"
				:tableData="tableData_vipcard"
			></base-table>
    </template>

    <template v-if="activeTab==2">
			<div class="text-sm mt-4 mb-2">结算表（单位：元）
				<!-- 旺店通默认 -->
				<a v-if="financial_account_id==2" type="link" class="ml-4" @click="handlerSettleDetail">明细</a>
			</div>
			<base-table style="white-space: pre-line;"
				:columnsData="columns_settle"
				:tableData="tableData_settle">
					<template #amount_1="{text}">
						<a-button class="p-0" type="link" @click="handlerExportFlow(1)">-{{formatEnMoney(text)}}</a-button>
					</template>
					<template #amount_2="{text}">
						<a-button class="p-0" type="link" @click="handlerExportFlow(2)">{{formatEnMoney(text)}}</a-button>
					</template>
					<template #amount_3="{text}">
						<a-button class="p-0" type="link" @click="handlerExportFlow(3)">{{formatEnMoney(text)}}</a-button>
					</template>
					<template #amount_4="{text}">
						<a-button class="p-0" type="link" @click="handlerExportFlow(4)">-{{formatEnMoney(text)}}</a-button>
					</template>
			</base-table>
    </template>

    <template v-if="activeTab==4">
			<div class="flex justify-between items-center mt-2">
				<div class="text-sm">
					<span v-if="end_date">截至 {{end_date}}日 24时那一刻，</span>
					<span v-else>截至当前，</span>
					本财务账户的订单预收款（*已收款未交付）
				</div>
				<a-button class="p-0" type="link" @click="exportWaiting">导出明细</a-button>
			</div>
			<div>
				<base-table style="max-width:500px;"
					:columnsData="columns_waiting"
					:tableData="tableData_waiting"
				></base-table>
			</div>
    </template>
		
  </div>
</template>

<script>
import pageData from "./columns"
import { 
	financeAccountOrder, 
	financeAccountWaitingOrder,
	// financeAccountVipcard, 
	financeAccountSettle,
	financeAccountExportSettle1,
	financeAccountExportSettle2,
	financeAccountExportSettle3,
	financeAccountExportSettle4,
	financeAccountExportSettle5,
	financeAccountExportSettle6,
	// financeAccountWDTSettleDetail
} from "@/api/finance/finance.js"
import { 
	exportFinancialWaitingOrder,
} from "@/api/finance/analysis.js"
import { formatEnMoney } from "@/utils/index.js"
import { checkAuth } from '@/utils/authData.js'

export default {
  components: {  },
  data() {
    return {
			formatEnMoney,
			tabList: [
				{
					id: 0,
					auth: "order",
					name: "订单数据",
				},
				{
					id: 2,
					auth: "settle",
					name: "结算表",
				},
				{
					id: 4,
					auth: "order",
					name: "订单预收款",
				},
			],

			activeTab: -1,
			search_date: [],
			end_date: "",
			financial_account_id: '',
			financeAccountList: [],

			...pageData,
			tableData_order: [],
			columns_order: [
				{
					title: "有效订单数",
					dataIndex: "place_order_count",
					align: "center",
				},
				{
					title: "有效订单总金额(元)",
					dataIndex: "order_amount",
					align: "center",
					slots: {
						customRender: "order_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "其中已完成订单数",
					dataIndex: "finish_count",
					align: "center",
				},
				{
					title: "已完成订单总金额(元)",
					dataIndex: "finish_amount",
					align: "center",
					slots: {
						customRender: "finish_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
					// slotsFunc: (val) => {return val ? val : "——"},
				},
			],
			tableData_refund: [],
			columns_refund: [
				{
					title: "退费订单数",
					dataIndex: "refund_order_count",
					align: "center",
				},
				{
					title: "退费金额(元)",
					dataIndex: "refund_amount",
					align: "center",
					slots: {
						customRender: "refund_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			tableData_pay: [],

			tableData_source: [],

			tableData_vipcard: [],
			columns_vipcard_recharge: [
				{
					title: "充值笔数",
					dataIndex: "recharge_order_count",
					align: "center",
				},
				{
					title: "充值面额(元)",
					dataIndex: "recharge_denomination",
					align: "center",
					slots: {
						customRender: "recharge_denomination",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "充值支付金额(元)",
					dataIndex: "recharge_pay_amount",
					align: "center",
					slots: {
						customRender: "recharge_pay_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			columns_vipcard_online: [
				{
					title: "在线消费笔数",
					dataIndex: "online_consumption_order_count",
					align: "center",
				},
				{
					title: "在线消费面额(元)",
					dataIndex: "online_consumption_amount",
					align: "center",
					slots: {
						customRender: "online_consumption_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "对应充值金额(元)",
					dataIndex: "online_consumption_recharge_discount_amount",
					align: "center",
					slots: {
						customRender: "online_consumption_recharge_discount_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			columns_vipcard_offline: [
				{
					title: "线下消费笔数",
					dataIndex: "offline_consumption_order_count",
					align: "center",
				},
				{
					title: "线下消费面额(元)",
					dataIndex: "offline_consumption_amount",
					align: "center",
					slots: {
						customRender: "offline_consumption_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "对应充值金额(元)",
					dataIndex: "offline_consumption_recharge_discount_amount",
					align: "center",
					slots: {
						customRender: "offline_consumption_recharge_discount_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			tableData_settle: [],
			columns_settle: [
				{
					title: "结算账户",
					dataIndex: "financial_account_name",
					align: "center",
				},
				{
					title: "订单结算\n应付",
					dataIndex: "amount_1",
					align: "center",
					slots: { customRender: "amount_1" },
				},
				{
					title: "订单结算\n应收",
					dataIndex: "amount_2",
					align: "center",
					slots: { customRender: "amount_2" },
				},
				{
					title: "订单退款\n应收",
					dataIndex: "amount_3",
					align: "center",
					slots: { customRender: "amount_3" },
				},
				{
					title: "订单退款\n应付",
					dataIndex: "amount_4",
					align: "center",
					slots: { customRender: "amount_4" },
					// slotsType: "format",
					// slotsFunc: (val) => { return Number(val) > 0 ? ("-"+val) : val},
				},
				{
					title: "总计",
					align: "center",
					slots: { customRender: "total_amount" },
					slotsType: "all",
					// slotsFunc: (val) => {return Math.round(
					// 				-Number(val.amount_1) + Number(val.amount_2) 
					// 				+ Number(val.amount_3) - Number(val.amount_4) 
					// 				- Number(val.amount_5.amount)+Number(val.amount_6.amount)
					// 			)*100/100
					// },
					slotsFunc: (val) => {return formatEnMoney(
									-Number(val.amount_1) + Number(val.amount_2) + Number(val.amount_3) - Number(val.amount_4),
									true
								)
					},
				},
			],

			columns_waiting: [
				{
					title: "订单数",
					dataIndex: "order_count",
					align: "center",
				},
				{
					title: "预收款总额(元)",
					dataIndex: "order_pay_flow_amount",
					align: "center",
				}
			],
			tableData_waiting: [],


			isLoading: false,
			loadingHideCallback: null,

			hasAuthOrder: checkAuth('financial-account-bill:order'),
			hasAuthSettle: checkAuth('financial-account-bill:settle'),
			hasAuthSettleKj: checkAuth('financial-account-bill:settle-kj'),
    }
  },
  mounted() {
    this.search_date = [
			this.getThisMonthFirstDay(),
			this.getToday(),
		]

		try{
			let list = localStorage.getItem('financial_account_list')
					list = JSON.parse(list)
			if(list) {
				this.financeAccountList = list
				this.financial_account_id = list[0].financial_account_id
			}
		}catch(e){
			console.log(e)
		}

		this.tabList = this.tabList.filter(el=>{
			if(this.hasAuthOrder && el.auth == "order"){
				return true
			}
			if(this.hasAuthVipcard && el.auth == "vipcard"){
				return true
			}
			if((this.hasAuthSettle||this.hasAuthSettleKj) && el.auth == "settle"){
				return true
			}
			return false
		})
		if(this.tabList.length){
			this.activeTab = this.tabList[0].id

			if(this.financial_account_id){
				this.handlerSearch()
			}
		}
  },
  methods: {
    changeAccount(){
			this.handlerSearch()
    },

		onChangeEndDate(){
			this.handlerSearch()
		},

    changeTabs(v){
      this.activeTab = v
      this.handlerSearch()
    },

    // 搜索
		handlerSearch() {
			if (this.search_date.length < 2) {
				this.$message.warning("请选择日期")
				return
			}
			if(this.activeTab == 0){
				this.initDataOrder()
      }
			// if(this.activeTab == 1){
			// 	this.initDataVipcard()
			// }
      if(this.activeTab == 2){
				this.initDataSettle()
      }
      if(this.activeTab == 4){
				this.initDataWaitingOrder()
      }
		},

		async initDataOrder() {
			this.showLoading()
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + '00:00:00',
				time_end:   this.search_date[1] + '23:59:59'
			}
			const { data, code } = await financeAccountOrder(params)
			this.hideLoading()
			if (code === 0) {
				let third_plat = 
						data.order_source_data[20] + 
						data.order_source_data[21] + 
						data.order_source_data[22] + 
						data.order_source_data[23];
				data.order_source_data['third_plat'] = third_plat
				// 支付方式分布 金额格式化
				for(let i in data.order_amount_data){
					data.order_amount_data[i] = formatEnMoney(data.order_amount_data[i])
				}

				this.tableData_order = [data]
				this.tableData_refund = [data]
				this.tableData_source = [data.order_source_data]
				this.tableData_pay = [data.order_amount_data]
			}
    },

   //  async initDataVipcard() {
			// this.showLoading()
			// const params = {
			// 	financial_account_id: this.financial_account_id,
			// 	time_start: this.search_date[0] + '00:00:00',
			// 	time_end:   this.search_date[1] + '23:59:59'
			// }
			// const { data, code } = await financeAccountVipcard(params)
			// this.hideLoading()
			// if (code === 0) {
			// 	this.tableData_vipcard = [data]
			// }
   //  },

    async initDataSettle() {
			this.showLoading()
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + '00:00:00',
				time_end:   this.search_date[1] + '23:59:59'
			}
			const { data, code } = await financeAccountSettle(params)
			this.hideLoading()
			if (code === 0) {
				if(!this.hasAuthSettle && this.hasAuthSettleKj){
					data.list = data.list.filter(el=>el.financial_account_id==1)
				}
				this.tableData_settle = data.list
			}
    },

		async initDataWaitingOrder(){
			this.showLoading()
			this.tableData_waiting = []
			const params = {
				financial_account_id: this.financial_account_id,
				time_end:   this.end_date ? (this.end_date + ' 23:59:59') : ''
			}
			const { data, code } = await financeAccountWaitingOrder(params)
			this.hideLoading()
			if (code === 0) {
				this.tableData_waiting = [data]
			}
		},

		showLoading(){
			this.hideLoading()
			this.isLoading = true
			this.loadingHideCallback = this.$message.loading("加载中...", 0)
		},
		hideLoading(){
			if(this.loadingHideCallback){
				this.loadingHideCallback()
				this.loadingHideCallback = null
			}
			this.isLoading = false
		},

		exportWaiting(){
			exportFinancialWaitingOrder({
				financial_account_id: this.financial_account_id,
				time_end:   this.end_date ? (this.end_date + ' 23:59:59') : ''
			})
		},

    
		handlerExportFlow(type){
			let params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + '00:00:00',
				time_end:   this.search_date[1] + '23:59:59',
			}

			if(!this.hasAuthSettle && this.hasAuthSettleKj){
				params.target_financial_account_id = 1;
			}
			
			if(type == 1) financeAccountExportSettle1(params)
			if(type == 2) financeAccountExportSettle2(params)
			if(type == 3) financeAccountExportSettle3(params)
			if(type == 4) financeAccountExportSettle4(params)
			if(type == 5) financeAccountExportSettle5(params)
			if(type == 6) financeAccountExportSettle6(params)
		},

		// -----------
		getLastMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			// 1月：上一个月 是去年12月
			if(month == 0){
				month = 11
				year = year-1
			}else{
				month = month-1
			}
			return this.formatDate(new Date(year, month, 1))
    },
    getLastMonthLastDay(){
			let date = new Date()
			date.setTime(date.getTime() - date.getDate()*24*3600*1000)

			return this.formatDate(date)
    },
    getThisMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			return this.formatDate(new Date(year, month, 1))
    },
    getToday(){
			let date = new Date()
			return this.formatDate(date)
    },

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style>
</style>