<template>
  <a-modal
    :visible.sync="show"
    :title="'订单支付（'+totalAmount+'元）'"
    destroyOnClose
    width="550px"
    @cancel="handlerCancel"
    :footer="null"
  >
  <div class="justify-center">
    <div style="width:100%;">
      <a-row>
        <a-col :span="24">
          <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="支付方式">
            <a-radio-group v-model="payMethod">
              <a-radio class="pay-method" :value="1">挂账-本店已收款</a-radio>
              <!-- <a-radio class="pay-method" :value="2">挂账-其他店已收款（<span>
                {{otherPayOutlets.outlets_name}}</span>
                <a-button type="link" size="small" class="p-0" 
                  @click="handlerShowShopList">更换</a-button>
              ）</a-radio> -->
              <a-radio class="pay-method" :value="10">货到付款</a-radio>
              <!-- <a-radio class="pay-method" :value="-1">饿了么订单</a-radio> -->

            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>

      <!-- 其他支付 -->
      <template v-if="payMethod==1 || payMethod==2" class="mt-5">
        <a-row class="mt-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" 
              label="付款凭证" class="upload-class" style="margin-bottom:0;">
              <uploadImage type="30" multiple :value.sync="img_files" @getList="(list)=>{img_files = list}" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="mb-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="支付流水号" style="margin-bottom:0;">
              <a-input v-model="artificial_pay_flow_no"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="mb-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="备注" style="margin-bottom:0;">
              <a-input type="textareas" v-model="remarks"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </template>
      <!-- 货到付款 -->
      <div v-if="payMethod==10" class="mt-5">
        <a-row class="mb-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" 
              label="预期方式">
              <a-select v-model="subCODType" style="width:100%;">
                <a-select-option
                  v-for="item in subCODTypeOptions"
                  :key="item.id"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>

      <div class="mt-5 mb-5 flex justify-center">
        <!-- <a-button type>取消</a-button> -->
        <a-button type="primary" @click="handlerPay">确定</a-button>
      </div>
    </div>
  </div>
    
  </a-modal>
</template>

<script>
import { subCODPayTypeList } from '@/utils/type'
import { setOrderPayType } from '@/api/outlets'
import uploadImage from "@/components/upload-image"

export default {
  components: { uploadImage },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
    orderType: {
      type: Number,
      default: 2,
    },
    totalAmount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      payMethod: 0,
      // otherPayOutlets: {
      //   outlets_id: this.orderData.source_outlets_id,
      //   outlets_name: this.orderData.source_outlets_name,
      // },

      artificial_pay_flow_no: "",
      remarks: "",
      img_files: [],
      subCODType: 0,
      subCODTypeOptions: [{ 
        id: 0, 
        name: "无" 
      }].concat(subCODPayTypeList),
    }
  },
  mounted() {
  
  },
  methods: {
    handlerPay() {
      let params = {}
      if(this.payMethod == 1){
        params = {
          order_no: this.orderId,
          pay_type: 20,
          artificial_pay_flow_no: this.artificial_pay_flow_no,
          attached_image_paths: this.img_files.join(","),
          remarks:  this.remarks,
        }
      }
      // if(this.payMethod == 2){
      //   params = {
      //     order_no: this.orderId,
      //     pay_type: 20,
      //     pay_outlets_id: this.otherPayOutlets.outlets_id,
      //     artificial_pay_flow_no: this.artificial_pay_flow_no,
      //     attached_image_paths: this.img_files.join(","),
      //     remarks:  this.remarks,
      //   }
      // }
      if(this.payMethod == 10){
        params = {
          order_no: this.orderId,
          pay_type: 10,
          pay_subtype: this.subCODType
        }
      }
      if(!params.pay_type){
        this.$message.info('请选择收款方式')
        return
      }

      const _this = this
      // 本店收款
      if(this.payMethod == 1){
        this.$confirm({
          title: "确认本店已收款 "+this.totalAmount+" 元？",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            setOrderPayType(params).then(res=>{
              if(res.code == "0"){
                _this.$message.success('操作成功')
                _this.$emit('paystatus')
              }
            })
          }
        })
      }
      // // 他店收款
      // if(this.payMethod == 2){
      //   this.$confirm({
      //     title: "确认"+this.otherPayOutlets.outlets_name+"已收款 "+this.totalAmount+" 元？",
      //     okText: "确认",
      //     cancelText: "取消",
      //     onOk() {
      //       setOrderPayType(params).then(res=>{
      //         if(res.code == "0"){
      //           _this.$message.success('操作成功')
      //           _this.$emit('paystatus')
      //         }
      //       })
      //     }
      //   })
      // }
      // 货到付款
      if(this.payMethod == 10){
        setOrderPayType(params).then(res=>{
          if(res.code == "0"){
            _this.$message.success('操作成功')
            _this.$emit('paystatus')
          }
        })
      }
    },

    handlerCancel(){
      this.$emit('update:show', false)
    }
  }
}
</script>

<style>
.pay-method{
  display: block;
  padding: 10px 0;
}
</style>